import { request } from "../utils/axios";
export default {
	
	getManagerList(params = {}){
		return request('get', '/system/get_manager_list', params);
	},
	
	deleteManager(params = {}){
		return request('get', '/system/delete_manager', params);
	},
	
	disableManager(params = {}){
		return request('get', '/system/disable_manager', params);
	},
	
	updateManagerPwd(params = {}){
		return request('post', '/system/update_manager_pwd', params);
	},
	
	recoverManager(params = {}){
		return request('get', '/system/recover_manager', params);
	},
	
	saveInitData(params = {}){
		return request('get', '/system/save_init_data', params);
	},
	
	saveManager(params = {}){
		return request('post', '/system/save_manager', params);
	},
	
	cogradientManagerPower(params = {}){
		return request('post', '/system/cogradient_manager_power', params);
	},
	
	getManagerPowerList(params = {}){
		return request('get', '/system/get_manager_power_list', params);
	},
	
	updateManagerPower(params = {}){
		return request('post', '/system/update_manager_power', params);
	},
	
	updateManagerListPower(params = {}){
		return request('post', '/system/update_manager_list_power', params);
	},
	
	initPowerData(params = {}){
		return request('get', '/system/init_power_data', params);
	},
	
	updateManagerListPower(params = {}){
		return request('post', '/system/update_manager_list_power', params);
	}
}