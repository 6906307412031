<template>
  <el-dialog
    title="复制权限"
    :visible.sync="dialogTableVisible"
    width="20%"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="onCloseDialog('formRules')"
  >
    <el-form
      ref="formRules"
      :model="formData"
      :rules="formRules"
      size="small"
      label-width="80px"
    >
      <el-form-item label="源账号" prop="sourceManagerGuid">
        <el-select
          v-model="formData.sourceManagerGuid"
          placeholder="请选择源账号"
        >
          <el-option
            v-for="(item, index) in tableData"
            :key="item.ManagerGuid"
            :label="item.ManagerAdmin"
            :value="item.ManagerGuid"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="目标账号" prop="managerGuid">
        <el-select v-model="formData.managerGuid" placeholder="请选择目标账号">
          <el-option
            v-for="(item, index) in tableData"
            :key="item.ManagerGuid"
            :label="item.ManagerAdmin"
            :value="item.ManagerGuid"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onCloseDialog('formRules')">取 消</el-button>
      <el-button type="primary" @click="submitForm('formRules')"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import api from "../../../../api/management.js";
export default {
  data() {
    return {
      formData: {
        sourceManagerGuid: "",
        managerGuid: "",
      },
      formRules: {
        sourceManagerGuid: [
          { required: true, message: "请选择源账号", trigger: "change" },
        ],
        managerGuid: [
          { required: true, message: "请选择目标账号", trigger: "change" },
        ],
      },
      dialogTableVisible: false,
      tableData: [],
    };
  },
  methods: {
    onCloseDialog(formName) {
      this.$refs[formName].resetFields();
      this.dialogTableVisible = false;
    },

    openCopyPermissions(event) {
      this.tableData = event;
      this.dialogTableVisible = true;
    },

    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            let { data, errcode, errmsg } = await api.cogradientManagerPower(
              this.formData
            );
            if (errcode == 0) {
              this.$message({
                message: "复制成功!",
                type: "success",
              });
              this.onCloseDialog("formRules");
            } else {
              this.$message.error(errmsg);
            }
          } catch (e) {
            //TODO handle the exception
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style>
</style>