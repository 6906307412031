<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="dialogTableVisible"
      width="700px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog('formRules')"
    >
      <el-form
        ref="formRules"
        :model="formData"
        :rules="formRules"
        size="small"
        label-width="100px"
      >
        <el-row :gutter="20" v-if="!passwordDisabled">
          <el-col :span="12">
            <el-form-item label="账号" prop="ManagerAdmin">
              <el-input v-model="formData.ManagerAdmin"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="密码" prop="ManagerPwd">
              <el-input v-model="formData.ManagerPwd"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20" v-if="!passwordDisabled">
          <el-col :span="12">
            <el-form-item label="确认密码" prop="ManagerPwdCheck">
              <el-input v-model="formData.ManagerPwdCheck"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="姓名" prop="ManagerName">
              <el-input v-model="formData.ManagerName"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20" v-else>
          <el-col :span="12">
            <el-form-item label="账号" prop="ManagerAdmin">
              <el-input v-model="formData.ManagerAdmin"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="姓名" prop="ManagerName">
              <el-input v-model="formData.ManagerName"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="手机号">
              <el-input v-model="formData.ManagerPhone"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="地址">
              <el-input v-model="formData.ManagerAddress"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="所属门店" prop="RoleNo">
              <el-select
                v-model="formData.StoresGuid"
                :disabled="storesDisabled"
                placeholder="请选择门店"
              >
                <el-option
                  v-for="(item, index) in initData.listStoresName"
                  :key="item.StoresGuid"
                  :label="item.StoresName"
                  :value="item.StoresGuid"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="系统角色" prop="RoleNo">
              <el-select v-model="formData.RoleNo" placeholder="请选择系统角色">
                <el-option
                  v-for="(item, index) in initData.roleNoList"
                  :key="item.RoleNo"
                  :label="item.RoleName"
                  :value="item.RoleNo"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="账号类型" prop="ManagerType">
              <el-select
                v-model="formData.ManagerType"
                placeholder="请选择账号类型"
              >
                <el-option
                  v-for="(item, index) in initData.adminType"
                  :key="item.RoleNo"
                  :label="item.Name"
                  :value="item.Value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="开通手机查询">
              <el-select
                v-model="formData.PhoneSeach"
                placeholder="请选择系统角色"
              >
                <el-option :label="'否'" :value="0"> </el-option>
                <el-option :label="'是'" :value="1"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="开通监管账单">
              <el-select
                v-model="formData.Supervision"
                placeholder="请选择开通监管账单"
              >
                <el-option :label="'否'" :value="0"> </el-option>
                <el-option :label="'是'" :value="1"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="数据权限">
              <div class="row">
                <el-select v-model="formData.DataLimitMode">
                  <el-option
                    v-for="item in initData.limitModeList"
                    :key="item.Value"
                    :label="item.Name"
                    :value="item.Value"
                  >
                  </el-option>
                </el-select>

                <el-input
                  v-model="formData.DataLimitNum"
                  placeholder=""
                  clearable
                  :maxlength="3"
                  :disabled="
                    formData.DataLimitMode == 0 || formData.DataLimitMode > 10
                  "
                ></el-input>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="备注" prop="region">
              <el-input type="textarea" v-model="formData.ManagerNote">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onCloseDialog('formRules')" size="small"
          >取 消</el-button
        >
        <el-button @click="submitForm('formRules')" size="small" type="primary"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/api/management.js";
export default {
  data() {
    return {
      dialogTableVisible: false,
      passwordDisabled: false,
      storesDisabled: false,
      initData: {},
      formData: {
        ManagerGuid: "",
        RoleNo: "",
        ManagerAdmin: "",
        ManagerPwd: "",
        ManagerPwdCheck: "",
        ManagerType: "",
        ManagerName: "",
        ManagerPhone: "",
        ManagerAddress: "",
        ManagerNote: "",
        PhoneSeach: 0,
        Supervision: 0,
        ManagerNumber: "",
        StoresGuid: "",
        DataLimitMode: 0,
        DataLimitNum: 0,
      },
      formRules: {
        ManagerAdmin: [
          { required: true, message: "请输入账号", trigger: "blur" },
        ],
        ManagerPwd: [
          { required: true, message: "请输入密码", trigger: "blur" },
        ],
        ManagerPwdCheck: [
          { required: true, message: "请输入确认密码", trigger: "blur" },
        ],
        ManagerName: [
          { required: true, message: "请输入姓名", trigger: "blur" },
        ],
        ManagerPhone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
        ],
        RoleNo: [
          { required: true, message: "请选择系统角色", trigger: "change" },
        ],
        ManagerType: [
          { required: true, message: "请选择所属平台", trigger: "change" },
        ],
      },
      title: "",
    };
  },

  methods: {
    async saveInitData(event) {
      try {
        let submitData = {
          manager_guid: event ? event.ManagerGuid : "",
        };
        let { data } = await api.saveInitData(submitData);
        this.initData = data;

        if (event) {
          let { editManager } = data;
          this.formData = { ...editManager };
          this.passwordDisabled = true;
          this.storesDisabled = true;

          this.title = "修改管理员";
        } else {
          this.passwordDisabled = false;
          this.storesDisabled = false;

          this.title = "添加管理员";

          this.formData.ManagerPwd = "";
          this.formData.ManagerPwdCheck = "";
        }
      } catch (e) {
        //TODO handle the exception
      }
    },

    onCloseDialog(formName) {
      this.$refs[formName].resetFields();
      this.dialogTableVisible = false;
    },

    async openAddAdmin(event) {
      this.saveInitData(event);
      this.dialogTableVisible = true;
    },

    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            let { data, errcode, errmsg } = await api.saveManager(
              this.formData
            );
            if (errcode == 0) {
              this.$message({
                message: "操作成功!",
                type: "success",
              });
              this.formData = {};
              this.$emit("addRoleSuccess");
              this.onCloseDialog("formRules");
            } else {
              this.$message.error(errmsg);
            }
          } catch (e) {
            //TODO handle the exception
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less">
.el-select {
  width: 100%;
}
.el-form {
  .el-row {
    margin-bottom: 0 !important;

    .row {
      .flex-row;

      .el-input,
      .el-select {
        margin-right: 10px;
      }
      > .el-input {
        width: 100px;
      }
      .el-select {
        flex: 1 !important;
        width: unset;
      }
    }
  }
}
</style>
