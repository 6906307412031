<template>
  <div>
    <el-dialog
      title="分管门店"
      :visible.sync="showDialog"
      width="800px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog"
    >
      <span class="content-box">
        <el-checkbox-group v-model="listStoresGuid">
          <el-checkbox
            v-for="(item, index) in listStoresName"
            :key="index"
            :label="item.StoresGuid"
            >{{ item.StoresName }}</el-checkbox
          >
        </el-checkbox-group>
      </span>
      <span slot="footer">
        <el-button @click="onCloseDialog">取 消</el-button>
        <el-button type="primary" @click="updateListStoresGuid"
          >保 存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import System from "@/api/system.js";

export default {
  data() {
    return {
      managerInfo: {
        ManagerAdmin: "",
        ManagerGuid: "",
        ManagerName: "",
        ManagerPhone: "",
        ManagerTypeName: "",
      },
      listStoresGuid: [],
      listStoresName: [],
      showDialog: false,
    };
  },

  mounted() {},

  methods: {
    // 初始化分管门店列表
    async initListStoresGuid() {
      try {
        let { data, errcode } = await System.initListStoresGuid({
          manager_guid: this.managerInfo.ManagerGuid,
        });
        if (errcode == 0) {
          this.listStoresGuid = data.listStoresGuid;
          this.listStoresName = data.listStoresName;
          this.showDialog = true;
        }
      } catch (err) {
        console.log(err);
      }
    },

    // 保存分管门店
    async updateListStoresGuid() {
      try {
        let { errcode } = await System.updateListStoresGuid({
          managerGuid: this.managerInfo.ManagerGuid,
          storesGuidList: this.listStoresGuid,
        });
        if (errcode == 0) {
          this.$message.success("保存成功");
          this.$emit("success");
          this.onCloseDialog();
        }
      } catch (err) {
        console.log(err);
      }
    },

    // 打开对话框
    onShowDialog(managerInfo) {
      this.managerInfo = JSON.parse(JSON.stringify(managerInfo));
      this.initListStoresGuid();
    },

    // 关闭对话框
    onCloseDialog() {
      this.showDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.content-box {
  .el-checkbox {
    margin: 5px;
  }
}
</style>
