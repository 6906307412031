<template>
  <!-- start 权限设置对话框 -->
  <el-dialog
    title="添加权限"
    :visible.sync="dialogTableVisible"
    width="50%"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="closeAddPermissions"
  >
    <!-- start 提交表格 -->
    <div class="formData">
      <el-form ref="formRules" size="small" label-width="120px">
        <el-form-item
          v-for="(item, index) in permissionsData"
          :key="index"
          :label="item.PowerClass"
        >
          <!-- 全选框 -->
          <el-checkbox
            v-model="checkAllArr[index]"
            @change="onCheckAll(index, checkAllArr[index])"
            >全选</el-checkbox
          >

          <!-- 权限多选框 -->
          <el-checkbox
            v-model="list.Checked"
            v-for="(list, listIndex) in item.PowerSelectorList"
            :key="listIndex"
            :checked="list.Checked"
            :disabled="list.Disabled"
            @change="onCheckChange(index)"
          >
            {{ list.PowerName }}
          </el-checkbox>
        </el-form-item>
      </el-form>
    </div>
    <!-- end 提交表格 -->

    <!-- start 腿部 -->
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="closeAddPermissions">取 消</el-button>
      <el-button size="small" type="primary" @click="submitForm"
        >确 定</el-button
      >
    </span>
    <!-- end 腿部 -->
  </el-dialog>
  <!-- end 权限设置对话框 -->
</template>

<script>
import api from "@/api/management.js";
export default {
  data() {
    return {
      dialogTableVisible: false, // 对话框显示
      checkAllArr: [], // 控制所有全选框数组
      permissionsData: [], // 权限列表
      managenment: {}, // 管理员信息
    };
  },

  methods: {
    // 关闭对话框
    closeAddPermissions() {
      this.dialogTableVisible = false;
      this.checkAllArr = [];
    },

    // 全选框点击事件
    onCheckAll(index, value) {
      this.permissionsData[index].PowerSelectorList.forEach((item) => {
        // 需要处理全选的时侯过滤掉禁用的选项
        item.Disabled == false ? (item.Checked = value) : null;
      });
    },

    // 权限多选框点击事件
    onCheckChange(index) {
      let check = this.permissionsData[index].PowerSelectorList.filter(
        (item) => {
          // 过滤禁用选项
          return item.Disabled == false ? !item.Checked : null;
        }
      );

      // 判断非禁用的数组长度是否等于当前所有选项的长度，是说明已全选，否说明非全选
      this.checkAllArr[index] = check.length > 0 ? false : true;
    },

    // 打开对话框
    async openAddPermissions(event) {
      try {
        let submitData = {
          role: event.RoleNo,
          manager_guid: event.ManagerGuid,
        };
        let { data } = await api.getManagerPowerList(submitData);

        // 判断初始化PowerSelectorList中每条选项是否有被选中
        data.forEach((item1, index1) => {
          let sum = 0;
          let length = 0;

          // 这里length是非禁用选项的总个数，sum是已被选择的个数，并且每一次循环都要初始为0，因为每一次PowerSelectorList的长度都不同
          item1.PowerSelectorList.forEach((item2, index2) => {
            item2.Checked ? sum++ : null;
            item2.Disabled ? null : length++;
          });

          this.checkAllArr.push(sum == length ? true : false);
        });
        this.permissionsData = data;
        this.managenment = event;
      } catch (e) {}
      this.dialogTableVisible = true;
    },

    // 权限提交事件
    async submitForm() {
      try {
        let tArray = [];
        this.permissionsData.forEach((list) => {
          list.PowerSelectorList.forEach((item) => {
            if (item.Checked) {
              tArray.push(item.PowerNo);
            }
          });
        });
        let submitData = {
          managerPowerList: tArray,
          managerGuid: this.managenment.ManagerGuid,
        };
        let { data, errcode } = await api.updateManagerPower(submitData);
        if (errcode == 0) {
          this.$message({
            message: "修改权限成功！",
            type: "success",
          });
          this.closeAddPermissions();
        } else {
          this.$message.error("修改权限失败！");
        }
      } catch (e) {
        //TODO handle the exception
      }
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep .el-dialog {
  .formData {
    height: 50vh;
    overflow-y: scroll;
  }
}
</style>