<template>
  <el-dialog
    title="修改密码"
    :visible.sync="dialogTableVisible"
    width="20%"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="onCloseDialog('formRules')"
  >
    <el-form
      ref="formRules"
      :model="formData"
      :rules="formRules"
      size="small"
      label-width="80px"
    >
      <el-form-item label="新的密码" prop="newPassWord">
        <el-input
          v-model="formData.newPassWord"
          type="password"
          placeholder="请输入新的密码"
        ></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="checkPassWord">
        <el-input
          v-model="formData.checkPassWord"
          type="password"
          placeholder="请输入新的密码"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onCloseDialog('formRules')" size="small"
        >取 消</el-button
      >
      <el-button @click="submitForm('formRules')" size="small" type="primary"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import api from "../../../../api/management.js";
export default {
  data() {
    return {
      formData: {
        newPassWord: "",
        checkPassWord: "",
        managerGuid: "",
        storesGuid: "",
      },
      formRules: {
        newPassWord: [
          { required: true, message: "请输入新的密码", trigger: "blur" },
        ],
        checkPassWord: [
          { required: true, message: "请输入确认密码", trigger: "blur" },
        ],
      },
      dialogTableVisible: false,
    };
  },
  methods: {
    onCloseDialog(formName) {
      this.$refs[formName].resetFields();
      this.dialogTableVisible = false;
    },

    openEditPassword(event) {
      console.log(event);
      this.formData.managerGuid = event.ManagerGuid;
      this.formData.storesGuid = event.StoresGuid;
      this.dialogTableVisible = true;
    },

    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            let { data, errcode, errmsg } = await api.updateManagerPwd(
              this.formData
            );
            if (errcode == 0) {
              this.$message({
                message: "修改成功!",
                type: "success",
              });
              this.onCloseDialog("formRules");
            } else {
              this.$message.error(errmsg);
            }
          } catch (e) {
            //TODO handle the exception
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style>
</style>